.w-100 {
  width: 100%;
}

.p-0 {
  padding: 0 !important;
}

.pb-5 {
  padding-bottom: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.m-0 {
  margin: 0;
}

.mb-5 {
  margin-bottom: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.lh-half {
  line-height: 2.5;
}

.grey-border-b {
  border-bottom: solid 0.5px $light-gray;
}

.grey-border-t {
  border-top: solid 0.5px $light-gray;
  margin-bottom: 0;
  margin-top: 20px;
}

.text-bold {
  font-weight: bold;
}

.text-bold-black {
  font-weight: bold;
  color: $black;
}

.text-blue {
  color: $blue-zee !important;
}

.text-underline {
  text-decoration: underline;
}

.flex {
  display: flex;

  &.center {
    justify-content: center;
    align-items: center;
  }
}

.hidden {
  display: none;
}

.upload-csv-file-halls {
  display: flex;
  align-items: center;

  // margin-bottom: 25;
  .ant-upload-list.ant-upload-list-text {
    margin: 0 0 10px 15px;
  }

  .ant-upload-list-item-name.ant-upload-list-item-name-icon-count-1 {
    padding-right: 50px;
  }

  span.ant-upload-list-item-card-actions svg {
    font-size: 16px;
  }

  .ant-upload-list-item-name.ant-upload-list-item-name-icon-count-1 {
    max-width: 32ch;
  }
}

.upload-photos-category {
  // text-align: end;
  margin-top: 16px;
}

.section-wrapper {
  border-radius: 16px;
  border: 7px solid #ebebeb;
  padding: 16px;
  min-height: 48px;
}