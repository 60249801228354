@import '../../styles/variables.scss';

.infoBox {
  display: flex;
  flex-direction: column;
  min-width: 240px;
  max-width: 280px;
  position: relative;
  // width: 48%;
  // &:first-of-type {
  //   margin-right: 12px;
  // }

  &:first-of-type {
    margin-right: 24px;
  }

  &__title {
    text-transform: uppercase;
    font-weight: bold;
    color: $black;
    margin-bottom: 4px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 14px 16px;
    border: 1px solid $medium-gray;
    border-radius: 8px;
    height: 100%;
    position: relative;
  }

  &__edit {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0 4px !important;
    padding: 4px 8px !important;
  }

  &__actions {
    position: absolute;
    right: 0px;
    top: 0px;

    // &_cancel {
    //   color: $super-dark !important;
    //   background-color: $red-light !important;
    //   border-color: $red-light !important;
    // }

    // &_edit {
    //   margin-left: 10px;
    //   background-color: transparent !important;
    //   color: $black !important;
    // }

    // &_pause {
    //   background-color: transparent !important;
    //   color: $black !important;
    // }
  }

  &__details {
    display: flex;
    flex-direction: column;
    margin-left: 24px;

    &_frequency {
      text-transform: uppercase;
      font-weight: bold;
      // font-size: 15px;
      display: flex;
      align-items: center;
      color: $black;
      margin-bottom: 5px;
    }
  }
}

.pauseModal {
  &__title {
    margin: 0;
    font-weight: bold;
    font-size: 12;
    color: $black;

    & ~ span {
      color: $dark-gray;
    }
  }
  &__input {
    &:first-of-type {
      margin: 10px 0;
    }
    p {
      margin: 0;
    }
  }
  &__subtitle {
    margin: 0;
    font-size: 12px;
    font-weight: bold;
  }
}

.cancelModal {
  &__hasCode {
    border: none;
    outline: none;
    margin-top: 15px;
    background-color: transparent;
    text-decoration: underline;
    font-size: 12px;
    cursor: pointer;
  }
}

.frequencyModal {
  &__title {
    font-weight: bold;
  }
  &__radio {
    display: grid;
    column-gap: 10px;
    row-gap: 10px;
    grid-template-rows: 40px 40px;
    grid-template-columns: 22.5% 22.5% 22.5% 22.5%;
    width: 100%;

    label {
      border-radius: 4px !important;
      width: 60px;
      justify-self: center;
      text-align: center;
    }
  }
}

.creditCardModal {
  &__card {
    margin-bottom: 18px;
    box-shadow: none;
  }
  &__radio {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 60vh;
    overflow-y: auto;
    padding: 0 10px 10px 10px;

    label {
      height: auto !important;
      width: 100%;
      position: relative;
    }

    &_item {
      display: flex;
      flex-direction: column;
      padding-left: 20px;

      p {
        font-weight: bold;
        margin: 0;
      }
      // span {

      // }
    }
  }
}

.itensPopover {
  max-width: 300px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid $light-gray;
  max-height: 175px;
  overflow-y: auto;

  &:last-of-type {
    border: none;
  }

  &_content {
    max-width: 250px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    max-height: 180px;
    overflow-y: auto;

    &__id {
      font-size: 12px;
    }
    &__details {
      font-size: 12px;
      margin: 0;
    }
  }
  &_title {
    width: 200px;
    word-wrap: break-word;
    text-overflow: ellipsis;
    font-size: 14px;
    overflow: hidden;
    text-wrap: nowrap;
    margin: 0;
  }
  &__infoButton {
    border: none;
    background-color: transparent;
    margin-left: 4px;
    cursor: pointer;
  }
}
