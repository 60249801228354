.two-columns {
  display: flex;
}

.card-margin {
  margin-bottom: 7px;
}

.form-edit-produto {
  .ant-card-head {
    min-height: auto;

    .ant-card-head-title,
    .ant-card-extra {
      padding: 0;
    }
  }
  .ant-card-body {
    padding: 4px 24px 8px;
  }
  .product-image-upload {
    .ant-card-head-title {
      line-height: 30px;
    }
  }
}

.card-subtitle {
  display: block;
  font-size: 13px;
  margin-bottom: 16px;
}

.box {
  border-radius: 8px;
  background-color: #fff;
  padding: 16px 0;
}

.box-border {
  border-color: #ebebeb;
  border-style: solid;
}

.erp_desc {
  font-size: 13px;
  line-height: 30px;
  margin-bottom: 0;
  color: #2595d1;
}

.attribute-cards {
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  & > .ant-card-body {
    width: 100%;
    display: flex;
    overflow-x: scroll;
    padding-bottom: 24px;
    flex-grow: 1;
    .ant-skeleton {
      width: 300px;
    }
  }
}

.attribute-cards,
.custom-fields-card {
  .ant-card-head-title {
    h2 {
      margin-bottom: 4px;
    }
    span {
      font-size: 13px;
      font-weight: normal;
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 16px;
      display: block;
    }
  }
}

.attribute-card {
  .ant-card-head {
    position: relative;
    & > .ant-card-head-wrapper > .ant-card-extra {
      position: absolute;
      right: 12px;
      padding: 0;
      font-size: 13px;
    }
  }

  .ant-card-body {
    height: 250px;
    overflow-y: scroll;
  }
}

.new-value-attributes .add-new-attribute,
.new-value-attributes .cancel-new-attribute {
  color: #000;
  border: none;
  padding: 0;
}

.new-value-attributes,
.new-value-attributes .ant-input {
  width: 80%;
  position: relative;
}

.new-value-attributes .add-new-attribute {
  background: #50ebc5;
  color: #000;
  position: absolute;
  top: 4px;
  right: 21%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-value-attributes .cancel-new-attribute {
  background: none;
  width: 25px;
}

/* SKU */

.input-title-border {
  display: flex;
  flex-direction: column;
  position: relative;
  opacity: 0.5;

  &:last-of-type {
    margin-left: 4px;
    margin-right: 0;
  }
  max-width: 170px;
  margin: 0 4px;
}

.input-title-border p {
  position: absolute;
  top: -7px;
  left: 8px;
  font-size: 11px;
  z-index: 9;
  background: #fff;
  padding: 0 7px;
}

.input-title-border input {
  background: #fff !important;
}
