.editable-cell-value-wrap {
  // padding: 5px 12px;
  cursor: text;
  width: '100%';
  height: 26px;
  display: 'flex';
  align-items: 'center';
  cursor: 'text';
  padding: 4px 6px;
  border-radius: 4px;
  border: 1px solid transparent;
}

.editable-cell-value-wrap:hover {
  border: 1px solid #b3b3b3;
  padding: 4px 6px;
  height: 26px;
}
