.ant-alert:not(.dispatch-alert, .properties-alert, .pets-alert) {
  padding-left: 42px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 4px;
  border: 0;
  font-weight: 600;
  .ant-alert-icon {
    top: 13px;
    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.ant-alert.dispatch-alert {
  margin-bottom: 8px;
  .ant-alert-close-icon {
    border-radius: 4px;
    transition: opacity ease 300ms;
    &:hover {
      opacity: 0.6;
    }
    .ant-alert-close-text {
      font-size: 14px;
      font-weight: bold;
      color: #0a0a0a;
    }
  }
}

.ant-alert-error {
  background: $red-lighter;
}

.ant-alert-success {
  background: $lighter-success;
  .ant-alert-icon {
    color: $success;
  }
}

.ant-alert.pets-alert {
  .ant-alert-message {
    font-size: 14px;
  }
}
