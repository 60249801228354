.ant-descriptions {
  &.ant-descriptions-small .ant-descriptions-row > td {
    padding: 0 0 2px 0;
  }
  .ant-descriptions-item-label {
    color: $medium-gray;
    &::after {
      margin-left: 0;
    }
  }
}
