.ant-modal-wrap {
  .ant-modal {
    .ant-modal-content {
      border-radius: 16px;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
      // background-color: $lighter-gray;
      .ant-modal-header {
        .ant-modal-title {
          font-weight: 900;
        }
      }
    }
    // &.white-bg .ant-modal-content {
    //   background-color: white;
    // }
  }
}
.ant-modal.ant-modal-confirm {
  .ant-modal-confirm-btns {
    width: 100%;
    float: none;
    display: flex;
    justify-content: center;
  }

  .ant-modal-confirm-body > .anticon {
    display: none;
  }
  .ant-modal-confirm-body
    > .anticon
    + .ant-modal-confirm-title
    + .ant-modal-confirm-content {
    margin-left: 0;
    text-align: center;
  }
  .ant-modal-confirm-title {
    font-weight: bold;
    text-align: center;
  }
}

.ant-modal-header {
  // padding: 20px 24px;
  border-bottom: none;
  background: transparent;
}

// .ant-modal-body {
//   padding: 40px;
// }

.ant-modal-footer {
  padding: 24px;
  // padding: 23px;
  // padding-top: 0;
  text-align: left;
  border-top: none;
  border-radius: 0 0 8px 8px;
}

.ant-modal.centered-footer {
  .ant-modal-footer {
    text-align: center;
  }
}

.ant-modal.right-footer {
  .ant-modal-footer {
    text-align: right;
  }
}

.ant-modal-customer {
  .ant-modal-body {
    padding: 23px;
  }
}

.ant-modal-hubs,
.ant-modal-stores {
  .ant-modal-title {
    h2 {
      text-align: center;
      font-size: 20px;
      margin: 0;
    }
  }
  .ant-modal-body {
    padding: 0 40px;
    p {
      font-size: 16px;
      color: #333333;
      text-align: center;
      padding: 0 16px;
    }
    form {
      h3 {
        text-transform: initial;
        font-weight: bold;
        font-size: 14px;
        color: #333333;
        display: inline-block;
        margin-right: 6px;
      }
    }
  }
  .ant-modal-footer {
    padding: 20px 24px;
  }
  &__no-feedback {
    .ant-form-explain {
      display: none;
    }
  }
}

.ant-modal-shipping {
  .ant-modal-body {
    padding: 20px 24px 10px 24px;
  }
  .ant-modal-footer {
    text-align: right;
  }
}

.user-logTable {
  .ant-modal-body {
    padding-top: 0;
  }
  .ant-modal-footer {
    padding-top: 0;
    text-align: right;
  }
}

.modal-csv-import-export,
.modal-buy-and-earn {
  .ant-modal-body {
    padding: 0;

    div.ant-row {
      &:first-child {
        border-bottom: 2px #e2e2e2 solid;
        border-top: 2px #e2e2e2 solid;
        padding: 20px 20px;
      }
      padding: 20px 20px 0;
    }
  }
  .ant-modal-footer {
    text-align: right;
  }
}

.generate-cupons {
  .ant-modal-body {
    padding: 0 40px;
    p {
      margin-bottom: 0;
    }
  }
  .ant-modal-footer {
    text-align: left;
  }
}

.ant-modal-price-tables {
  margin: 40px 0;
  .ant-modal-body {
    padding: 0 25px 25px 25px;
  }

  .ant-modal-header {
    padding: 25px 25px 8px;
    text-transform: uppercase;
  }

  .ant-modal-close {
    top: 8px;
    right: 6px;
  }
}

.ant-modal-recom-addProducts {
  margin-top: 24px;
  .ant-modal-content {
    background-color: $white !important;
    border-radius: 8px !important;
  }

  .ant-modal-body {
    padding: 0 25px 25px 25px;
  }

  .ant-modal-header {
    padding: 28px 25px 20px;
    margin-bottom: 24px;
    text-transform: uppercase;
    border-bottom: 1px solid $gray;
  }
  .ant-modal-footer {
    text-align: right;
    padding: 15px 25px;
    border-top: 1px solid $gray;
  }

  .ant-modal-close {
    top: 8px;
    right: 6px;
  }
}

.ant-modal-recom-editStatus {
  .ant-modal-header {
    padding: 24px;
    text-align: center;

    .ant-modal-title {
      font-size: 20px;
    }
  }

  .ant-modal-body {
    padding: 20px 30px;
    text-align: center;

    span,
    p {
      font-size: 1rem;
    }

    p {
      margin-top: 20px;
    }
  }

  .ant-modal-footer {
    text-align: center;
    padding: 0 30px 30px 30px;
  }
}

.modal-sizes {
  form .ant-form-item {
    margin-bottom: 0;
  }
}

.ant-modal-scan {
  .ant-radio-wrapper {
    .ant-radio-inner {
      width: 20px;
      height: 20px;
      border-color: $medium-gray;
      &::after {
        width: 12px;
        height: 12px;
      }
    }
  }
}
