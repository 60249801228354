@import '../../../../styles/variables.scss';

.Tabs {
  position: absolute;
  top: 0;
  right: 0;
  width: 30%;
  height: 100%;
  background: $super-dark;
  color: $white;
  border-radius: 0 8px 8px 0;
  display: flex;
  flex-direction: column;
  &__container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: $white;
    &--status {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        max-width: 130px;
      }
      h2 {
        color: $white;
        text-align: center;
        margin-top: 20px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 1;
      }
      p {
        letter-spacing: 0.2px;
        font-weight: 300;
      }
    }
    &--zeeman {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      .zeeman-tab {
        &__header {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: $light-gray;
          margin: 1rem;
          padding: 0 0 8px 0;
          margin-bottom: 0;
          &--no-border {
            border: 0;
          }
          &--profile {
            min-width: 50px;
            min-height: 50px;
            max-height: 50px;
            max-width: 50px;
            background-size: cover;
            border-radius: 50%;
            background-position: center;
          }
          &--carrier {
            width: 50px;
            height: 30px;
            display: flex;
            align-items: center;
            margin-left: 10px;

            img {
              width: 100%;
              height: 100%;
            }
          }
          &--zeeman-info,
          &--hub-info {
            margin: 0 10px;
            p {
              color: $white;
              margin: 0;
              line-height: 1;
            }
            h3 {
              color: $blue-zee;
              font-weight: bold;
              line-height: 1.3;
              margin: 0;
              font-size: 0.8rem;

              a {
                color: inherit;
              }

              span {
                color: $dark-gray;
              }
            }
            a {
              color: inherit;
            }
          }
        }
        &__header_boarding_number {
          border-bottom: 1px solid $light-gray;
          margin: 1rem;
          padding: 0 0 8px 0;
          margin-bottom: 0;
        }
        &__delivery-box {
          position: relative;
          width: 100%;
          height: 100%;
          ul {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 0;
            margin: 0;
            height: 100%;
            overflow-y: auto;
            li {
              list-style: none;
              .zeeman-tab__delivery-box--order {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 1rem;
                position: relative;
                &::after {
                  content: '';
                  position: absolute;
                  width: 1px;
                  height: calc(100% - 2px);
                  border: 1px dashed white;
                  top: 0;
                  left: 25px;
                }
                &.delivery-box-single-item {
                  &::before,
                  &::after {
                    display: none;
                  }
                }
                .delivery-order {
                  &__position {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 20px;
                    height: 20px;
                    color: $black;
                    background: $white;
                    border-radius: 50%;
                    flex: none;
                    font-weight: 700;
                    z-index: 3;
                    &--delivered {
                      background: $success;
                    }
                    &--not-self:empty {
                      width: 12px;
                      height: 12px;
                      margin-left: 4px;
                      margin-bottom: 3px;
                    }
                    img {
                      width: 20px;
                      height: 20px;
                    }
                  }
                  &__eta {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin: 0 0.75rem;
                    min-width: 50px;
                    max-width: 50px;
                    text-align: center;
                    span {
                      width: 100%;
                      padding: 6px 0;
                      line-height: 1.09;
                      font-size: 0.8rem;
                      white-space: nowrap;
                    }
                    &--hour {
                      background: $black;
                      color: $white;
                      border-radius: 10px 10px 0 0;
                    }
                    &--time {
                      background: $white;
                      color: $dark-gray;
                      border-radius: 0 0 10px 10px;
                      text-align: center;
                    }
                  }
                  &__order-info {
                    h4 {
                      color: $white;
                      font-weight: 700;
                      line-height: 1;
                    }
                    p {
                      margin-bottom: 5px;
                      line-height: 1;
                      color: $medium-gray;
                    }
                  }
                }
              }
              &:first-child,
              &:last-child {
                .zeeman-tab__delivery-box--order {
                  &::before {
                    content: '';
                    position: absolute;
                    width: 1px;
                    height: calc(50% - 10px);
                    border: 1px solid $super-dark;
                    top: 0;
                    left: 25px;
                    z-index: 2;
                  }
                }
              }
              &:last-child {
                .zeeman-tab__delivery-box--order {
                  &::before {
                    top: calc(50% + 10px);
                  }
                }
              }
            }
          }
        }
        &__no-zeeman {
          color: $light-gray;
          text-align: center;
          padding: 0 2em;
          padding-top: 5em;
          height: 100%;
          max-width: 250px;
          margin: 0 auto;
        }
      }
    }
  }
}
