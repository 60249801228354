@import './variables.scss';
@import './mixins.scss';
@import './utility.scss';
@import './typography.scss';
@import './table.scss';
@import './tag.scss';
@import './pagination.scss';
@import './searchbar.scss';
@import './filter.scss';
@import './card.scss';
@import './menu.scss';
@import './timeline.scss';
@import './modal.scss';
@import './drawer.scss';
@import './collapse.scss';
@import './select.scss';
@import './form/button.scss';
@import './form/switch.scss';
@import './form/input-number.scss';
@import './form/label.scss';
@import './form/radio.scss';
@import './form/upload.scss';
@import './form/input.scss';
@import './form/select.scss';
@import './form/checkbox.scss';
@import './tooltips.scss';
@import './tabs.scss';
@import './notification.scss';
@import './alert.scss';
@import './recharts.scss';
@import './list.scss';
@import './products-list.scss';
@import './edit-product.scss';
@import './upload-image.scss';
@import './skeleton.scss';
@import './icon.scss';
@import './chips.scss';
@import './spin.scss';
@import './progress.scss';
@import './avatar.scss';
@import './slider.scss';
@import './descriptions.scss';

// variáveis para zeenow/zeedog
:root {
  --primary-color: #33d6ef;
  --menu-primary-color: #33d6ef;
  --menu-secondary-color: #d1f2f7;
}

body {
  font-family: proxima-nova, sans-serif;
  font-style: normal;
}

.FilterNav .ant-tabs {
  color: red;
}

hr {
  border: 1px solid $light-gray;
  margin: 30px 0;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.footer-form-save {
  background: #ffffff;
  border-top: 1px solid #ebebeb;
  padding: 10px 8px;
  left: 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  z-index: 5;
}

.ant-layout-content {
  padding: 24px 16px;
  @include mq('lg') {
    padding: 24px;
  }
}

.card-no-header {
  > .ant-card-head {
    position: absolute;
    width: 100%;

    .ant-card-extra {
      padding: 5px 0;
      z-index: 9;
    }
  }
}

.search-with-select {
  display: flex;

  input {
    border-left: 0;
    border-radius: 0;
    height: 44px !important;
  }

  .ant-select-lg .ant-select-selection--single {
    height: 44px;
    border-radius: 5px 0 0 5px;
  }
}

form
  .has-feedback
  :not(.ant-input-group-addon)
  > .ant-select
  .ant-select-arrow {
  right: 11px;
}

.ant-form-explain {
  font-size: 10px;
  padding: 5px 0 0;
}

.modalCenteredButtons {
  .ant-modal-footer div {
    display: flex;
    justify-content: center;
  }
}

#order-map .gm-style-moc {
  height: unset !important;
}
