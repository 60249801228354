.image-upload-default {
  display: flex;
  justify-content: center;
  background-color: #ebebeb;
  align-items: center;
}

.image-upload-default-round {
  display: flex;
  justify-content: center;
  background-color: #ebebeb;
  width: 100%;
  padding-top: 100%;
  border-radius: 50%;
  position: relative;
  i {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.image-upload-content-holder-round {
  width: 100%;
  padding-top: 100%;
  height: auto;
  position: relative;
}

.image-upload-content {
  height: 300px;
  max-width: 100%;
  border: 2px solid #ebebeb;
  object-fit: contain;
}

.image-upload-content-round {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.image-upload-small,
.image-upload-content-small {
  height: 180px;
}
