.ant-table {
  &-wrapper {
    padding: 0 !important;
  }
  &-small {
    .ant-table-content {
      .ant-table-body {
        margin: 0;
      }
    }
  }
  &-fixed-header {
    .ant-table-scroll .ant-table-header {
      opacity: 1;
      overflow: hidden;
      padding: 0;
      margin: 0;
    }
  }
  &-thead {
    tr {
      th {
        background-color: $light-gray;
        border-right: 1px solid #fff;
        &:hover {
          background-color: $light-gray;
        }
        &.ant-table-column-has-actions {
          &.ant-table-column-has-sorters {
            background-color: $light-gray;
            &:hover {
              background-color: $light-gray;
            }
          }
        }
        .ant-table-column-title {
          font-weight: 900;
          font-size: 13px;
        }
      }
    }
    tr:first-child > th:last-child {
      border-top-right-radius: 8px !important;
    }
    tr:first-child > th:first-child {
      border-top-left-radius: 8px !important;
    }
    tr > th .ant-table-header-column {
      vertical-align: middle;
    }
  }
  &-tbody {
    tr {
      td {
        border-bottom: 1px solid $light-gray;
        border-right: 1px solid white;
        font-size: 12px;
      }
    }
  }
  &-thead,
  &-tbody {
    tr {
      td {
        padding: 12px 14px;
      }
    }
  }
  &-row {
    &.banned {
      background-color: $red-lighter !important;
      svg path {
        fill: $dark-gray;
      }
    }
    &.has-alert {
      td:not(:last-child) {
        color: #fdab02;
        font-weight: bold;
      }
    }
    &.disabled {
      opacity: 0.5;
      background: #efefef;
      &:hover {
        cursor: not-allowed;
      }
    }
    &.has-click {
      cursor: pointer;
    }
  }
}
.table-results {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  &__orders {
    margin-top: 15.5px;
    position: absolute;
    z-index: 10;
    width: calc(100% - 580px);
    margin-bottom: 16px;
  }
  &__days-ago {
    position: absolute;
    z-index: 10;
    right: 0;
  }
  &__promos {
    height: 100%;
    align-items: center;
  }
  p {
    margin: 0;
    span {
      display: inline-block;
      font-weight: 900;
    }
  }
}

.ant-table-thead > tr > th {
  padding: 12px 12px;
}

.ant-table-wrapper {
  &.even-gray tr:nth-child(2n) {
    background-color: $lighter-gray;
  }
}
.price-indexing-table {
  .ant-table-row-expand-icon {
    line-height: 15px;
  }
}
.table-LastOrders {
  .ant-table-row-expand-icon {
    border: 0;
    line-height: 30px;
  }
  .ant-table-row-collapsed::after {
    font-size: 30px;
    width: 20px;
    height: 20px;
    color: #000000;
  }
  .ant-table-row-expanded::after {
    font-size: 40px;
    width: 20px;
    height: 20px;
    color: #000000;
  }
}

.table-padding-logUser {
  .ant-table-tbody tr {
    td {
      padding: 4px 16px;
    }
  }
}

.table-actions {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  // &__skip,
  // &__pause {
  //   border: none;
  //   cursor: pointer;
  //   line-height: 15px;
  //   padding: 8px 15px;
  //   border-radius: 4px;
  //   font-weight: bold;
  //   &:focus {
  //     outline: none;
  //   }
  // }

  // &__skip {
  //   background: $blue-zee;

  //   &:disabled {
  //     background: inherit;
  //   }
  // }
}

.tableEditDelete {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  &__delete,
  &__edit {
    background: none;
    border: none;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
}

.ant-table-select-drag {
  &__image {
    width: 60px;
    height: 60px;
    object-fit: cover;
    max-height: 60px;
    overflow: hidden;
  }

  // .ant-table-selection-column {
  //   padding: 12px 16px;
  //   text-align: right !important;
  // }

  .ant-table-column-title .ant-checkbox-wrapper {
    position: relative;
    &::after {
      content: 'TODOS';
      position: absolute;
      width: max-content;
      font-weight: 400;
      padding-left: 15px;
    }
  }
  // .ant-table-body {
  //   border: 1px solid $gray;
  //   border-radius: 4px;
  // }
  .ant-table-thead {
    th {
      border: none;
      // background-color: transparent;
      // &:hover {
      //   background-color: transparent;
      // }
    }
  }
}
