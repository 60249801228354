.ant-slider.pet-temperament-slider {
  cursor: default;
  &.ant-slider-disabled {
    & * {
      cursor: default !important;
    }
    .ant-slider-rail {
      background-color: #ebebeb !important;
    }
    .ant-slider-handle {
      border-color: $blue-zee !important;
    }
  }
  // &.empty {
  //   .ant-slider-handle {
  //     display: none;
  //   }
  //   .ant-slider-mark-text-active {
  //     color: rgba(0, 0, 0, 0.45);
  //   }
  // }
}
